@import '../../styles/variables';

.iconButton {
  font-size: inherit;
  border: none;
  outline: none;
  cursor: pointer;
}

.primary {
  background: $white;
  color: $blue;
}

.secondary {
  background: $light-grey;
  color: $blue;

  &:hover {
    color: $dark-grey;
  }
}

.small {
  padding: 4px;
}

.medium {
  padding: 8px;
}
