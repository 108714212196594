@import '../../styles/variables';

.textInput {
  width: 100%;
  padding: 8px 16px;
  font-size: 1.6rem;
  font-family: $font-primary;
  border: 2px solid $light-grey;
  border-radius: 4px;
  outline-color: $dark-grey;
}
