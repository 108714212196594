@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Open+Sans:wght@300;400;600;800&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */*{margin:0;padding:0;box-sizing:border-box}html{font-size:62.5%}body{font-size:1.6rem;font-family:"Cabin",sans-serif;line-height:1.5;background:#fff;color:#494949}h1,h2,h3,h4,h5,h6{font-family:"Open Sans",sans-serif;margin:1rem 0}p{font-size:1.6rem;margin:1rem 0}

.AppLayout_appLayout__24eE9{margin:0 auto;max-width:1400px;padding:0 20px}.AppLayout_appLayout__24eE9 h1{color:#00628c}.AppLayout_content__3KE4O{display:-webkit-flex;display:flex}.AppLayout_menu__15ov0{width:25%}.AppLayout_mainContent__1kaPK{width:75%;padding-right:20px}

.Menu_menuItem__2AA5Q{display:block;color:#494949;font-weight:bold;text-decoration:none}.Menu_menuItem__2AA5Q:hover{text-decoration:underline}.Menu_active__Vm0gp{color:#00628c}

.WriteEmail_inputGroup__2lCtU{margin-bottom:16px}.WriteEmail_recipientsContainer__vRSUT{display:-webkit-flex;display:flex;min-height:40px}.WriteEmail_marginRight__1iAHV{margin-right:16px}.WriteEmail_marginBottom__duYZF{margin-bottom:16px}.WriteEmail_textarea__18gkz{width:100%;padding:8px 16px;font-size:1.6rem;font-family:"Cabin",sans-serif;border:2px solid #ccc;border-radius:4px;outline-color:#494949}

.Header_header__10O8_{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;margin-bottom:16px}.Header_header__10O8_ h2{margin:0}.Header_header__10O8_ .Header_backButton__3wmOg{padding-left:0}

.IconButton_iconButton__3IsX2{font-size:inherit;border:none;outline:none;cursor:pointer}.IconButton_primary__3ylaz{background:#fff;color:#00628c}.IconButton_secondary__xsrnG{background:#ccc;color:#00628c}.IconButton_secondary__xsrnG:hover{color:#494949}.IconButton_small__2i-np{padding:4px}.IconButton_medium__3cI1Q{padding:8px}

.Label_label__P-445{display:inline-block;font-weight:bold}

.TextInput_textInput__wxSMT{width:100%;padding:8px 16px;font-size:1.6rem;font-family:"Cabin",sans-serif;border:2px solid #ccc;border-radius:4px;outline-color:#494949}

.EmailRecipients_emailRecipients__3GMnp{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;list-style:none}.EmailRecipients_emailRecipients__3GMnp .EmailRecipients_removeButton__2ol4o{padding:0;padding-left:4px}.EmailRecipients_recipient__1xMAu{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;margin-right:8px;margin-bottom:8px;padding:4px 8px;border-radius:4px;background:#ccc;color:#00628c}

.Button_button__2t4sO{padding:8px 16px;background:#00628c;color:#fff;font-size:1.8rem;font-family:"Cabin",sans-serif;border-radius:4px;border:none;outline:none;cursor:pointer}.Button_button__2t4sO:hover{color:#ccc}

.EmailPreview_emailPreview__3j96H{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;margin-bottom:8px;padding-bottom:8px;border-bottom:2px solid #ccc}.EmailPreview_emailPreview__3j96H:hover{cursor:pointer;color:#00628c}.EmailPreview_emailRecipients__B--ub,.EmailPreview_subject__3oU-b,.EmailPreview_message__dIfx7{width:33%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.EmailPreview_emailRecipients__B--ub,.EmailPreview_subject__3oU-b{padding-right:16px}

