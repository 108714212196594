@import '../../styles/variables';

.button {
  padding: 8px 16px;
  background: $blue;
  color: $white;
  font-size: 1.8rem;
  font-family: $font-primary;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: $light-grey;
  }
}
