@import '../../styles/variables';

.inputGroup {
  margin-bottom: 16px;
}

.recipientsContainer {
  display: flex;
  min-height: 40px;
}

.marginRight {
  margin-right: 16px;
}

.marginBottom {
  margin-bottom: 16px;
}

.textarea {
  width: 100%;
  padding: 8px 16px;
  font-size: 1.6rem;
  font-family: $font-primary;
  border: 2px solid $light-grey;
  border-radius: 4px;
  outline-color: $dark-grey;
}
