@import '../../styles/variables';

.emailPreview {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid $light-grey;

  &:hover {
    cursor: pointer;
    color: $blue;
  }
}

.emailRecipients,
.subject,
.message {
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailRecipients,
.subject {
  padding-right: 16px;
}
