@import '../../styles/variables';

.menuItem {
  display: block;
  color: $dark-grey;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.active {
  color: $blue;
}
