@import '../../styles/variables';

.appLayout {
  margin: 0 auto;
  max-width: $max-width;
  padding: 0 20px;

  h1 {
    color: $blue;
  }
}

.content {
  display: flex;
}

.menu {
  width: 25%;
}

.mainContent {
  width: 75%;
  padding-right: 20px;
}
