// Normalize.css styles
@import-normalize;

// Google fonts
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Open+Sans:wght@300;400;600;800&display=swap");

// Variables
@import "../styles/variables";

/* Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $font-primary;
  line-height: 1.5;
  background: $white;
  color: $dark-grey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  margin: 1rem 0;
}

p {
  font-size: 1.6rem;
  margin: 1rem 0;
}
