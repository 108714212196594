.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  h2 {
    margin: 0;
  }

  .backButton {
    padding-left: 0;
  }
}
