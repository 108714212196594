@import '../../styles/variables';

.emailRecipients {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  .removeButton {
    padding: 0;
    padding-left: 4px;
  }
}

.recipient {
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background: $light-grey;
  color: $blue;
}
